// 测试环境
// let baseUrl = 'http://localhost:11077';
let baseUrl = 'https://55th.us';


// 生产环境
// let baseUrl = ''

export default {
    baseUrl
};