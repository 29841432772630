<template>
  <div>
    <div class="chatBox" @click="goChat()"></div>
    <div class="chatIframe" v-if="showIframe">
      <div class="close" @click="showIframe = false"></div>
      <iframe
        :src="iframeSrc"
        width="100%"
        height="100%"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showIframe: false,
      iframeSrc: 'https://chart.55th.us/im/text/104eac.html',
    }
  },
  components: {},
  methods: {
    goChat() {
      this.showIframe = true
      // window.open(this.iframeSrc)
    },
  },
}
</script>

<style lang="less" scoped>
.chatBox {
  width: 100px;
  height: 100px;
  background: url('../../static/image/home/online2.gif') 100% 100%/100% 100%;
  cursor: pointer;
  position: fixed;
  right: 40px;
  bottom: 100px;
}
.chatIframe {
  width: 700px;
  height: 500px;
  position: fixed;
  right: 40px;
  bottom: 100px;
  z-index: 9999;
  .close {
    position: absolute;
    top: 16px;
    right: 100px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    background: url('../../static/image/home/close.png') 100% 100%/100% 100%;
  }
}
</style>
