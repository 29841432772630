export default {
  header: {
    The55thState: 'The 55th State',
    MyProfile: 'My Profile',
    PointShop: 'Shop',
    InvitationCenter: 'Invitation Center',
    Announcement: 'Announcement',
  },
  invite: {
    t1: 'Default Invitation',
    t2: 'Point System Rules >',
    t3: 'You have obtained',
    t4: 'Inviter',
    t5: 'My Invitation Code',
    t6: 'My Invitation Link',
    t7: 'Invite friends',
    t8: 'My Invitation',
    t9: 'Shop',
    t10: 'Rewards Redeem',
    t11: 'Invitation Rules',
    t12: 'Total Invites',
    t13: 'Accumulate Points',
    t14: 'Referrer Unique Identifier',
    p1: 'Market price',
    p2: 'Limited-time offer',
    p3: 'Referral price',
    p4: 'Remaining',
    p5: 'units',
    p6: 'Buy',
    p7: 'Approve',

    rule1:
      'Invitation Rules<br>1.Number of Invitations:<br>There is no limit to the number of friends a single account can invite.<br>2.Invitation Process:<br>Each of the 55th State Passport has a unique invitation code. An account owner could has multiple of the 55th  State Passports, and possess multiple invitation codes.<br>Individuals who accepts an invitation and complete real-name authentication becomes members of the 55th  State, and upon activating their the 55th State Passport, they become 55 State citizens.<br>3.Self-Invitation Prohibition:<br>Users are prohibited from using multiple accounts to invite themselves. Violations of this rule may result in legal responsibility, including but not limited to liability for impersonating others.<br>4.Data Synchronization:<br>After you successfully inviting friends, who also made purchases, your invitation status will be synchronized within 24 hours.<br>5.Rule Adjustment:<br>Due to changes in market conditions and fraud risks, the 55th State reserves the right to modify the reward rules at any time and will have the final interpretation.',
  },
  profile: {
    t1: 'Upload files, <br/> Join the 55th State.',
    t2: 'Upload your profile and join the 55th district metaverse virtua',
    t3: 'Step Tips',
    t4: 'Fill in your invitation code, which cannot be modified after adding',
    s1: 'Agreement',
    s2: 'Passport',
    s3: 'Hold your identity card/driver license ',
    s4: 'Pending review ',
    d1: 'Read and Agree<br/>to the terms',
    d2: 'Upload your<br/>passport photos',
    d3: 'Upload a photo of you holding<br/>your own driver license',
    d4: 'Review process usually<br/>finishes in 24 hours',
    upload1: 'Click to Upload Or your can drop your files here',
    upload2:
      'Photo requirements: Make sure the picture is clear, showing all four corners of your document orcredentials, and please avoid glare.',
    upload3:
      "Your information will be securely encrypted and reviewed by a designated specialist. It's safe to submit your information and ducuments.",
    upload4:
      'You have completed the information submission and the review will be completed within 24 hours!',
    submit: 'Submit',
  },
  common: {
    unLogin: 'Please connect your wallet first',
    send: 'Dispatch',
    submit: 'Submit',
    login: 'Login',
    register: 'Enrollment',
    message:
      'The materials have been submitted, please pay attention to your email',
    no_more: 'no data...',
    loading: 'loading...',
    search: 'search',
    select1: 'Choose a country',
    select2: 'Choose a type',
  },

}
