import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/pc/index'),
  },
  {
    path: '/MyProfile',
    name: 'MyProfile',
    component: () => import('@/views/pc/MyProfile'),
  },
  {
    path: '/PointShop',
    name: 'PointShop',
    component: () => import('@/views/pc/PointShop'),
  },
  {
    path: '/InvitationCenter',
    name: 'InvitationCenter',
    component: () => import('@/views/pc/InvitationCenter'),
  },
  {
    path: '/Announcement',
    name: 'Announcement',
    component: () => import('@/views/pc/Announcement'),
  },
  {
    path: '/passportList',
    name: 'passportList',
    component: () => import('@/views/pc/passportList'),
  },
  {
    path: '/goodsDetail',
    name: 'goodsDetail',
    component: () => import('@/views/pc/goodsDetail'),
  }
]

const router = new VueRouter({
  routes,
})
router.afterEach(() => {
  window.scrollTo(0, 0)
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err)
}

export default router
