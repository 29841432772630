<template>
  <div :class="$isMobile?'mobile':'pc'">
    <router-view></router-view>
   <!-- <Service-Chat></Service-Chat> -->
  </div>
</template>
<script>
import { getAll } from '@/api/global'
import ServiceChat from './components/ServiceChat.vue'
// import { getQueryParam } from './utils'
export default {
  components: {
    ServiceChat,
  },
  created() {
    this.getcfg()
  },
  mounted() {
  },
  methods: {
    getcfg() {
      getAll().then((response) => {
        sessionStorage.setItem('baseConfig', JSON.stringify(response.data))
      })
    }
  },
}
</script>

<style lang="less">
.mobile{
  height: 100vh;
  overflow: scroll;
}
.el-button--primary,
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #948060 !important;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(--font-icon-t3);
  background: transparent;
  background-color: #353b45;
}

::-webkit-scrollbar-thumb:hover {
  border-radius: 10px;
  background: var(--font-icon-t3);
}

::-webkit-scrollbar-track {
  background: var(--el-color-white);
}
</style>
