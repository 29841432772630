import axios from 'axios'
import { Message, Loading  } from 'element-ui'
import config from './env';
import i18n from '../tools/i18n.js'
// import store from '@/store'
// import { getToken } from '@/utils/auth'
// import router from '../router/index'

// create an axios instance
const service = axios.create({
    baseURL: `${config.baseUrl}`,
    // baseURL: "http://47.96.230.8:11077/", // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 8000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        if (window.sessionStorage.getItem('tk')) {
            config.headers['Authorization'] = JSON.parse(window.sessionStorage.getItem('tk')).token
        }
        Loading.service({
            lock: true,
            background: 'rgba(255, 255, 255, 0.3)'
        });
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        Loading.service().close();
        const res = response.data
        // console.log('success',res)
            // if the custom HttpStatusCode is not 200, it is judged as an error.
        if (res.code == -1) {
            Message({
                message: res.msg || 'Network Error',
                type: 'error',
                duration: 5 * 1000
            })
            return Promise.reject(new Error(res.message || 'Error'))
        } else if( res.code == 401){
            Message({
                message: i18n.t('common.unLogin'),
                type: 'error',
                duration: 5 * 1000
            })
            return Promise.reject(new Error(res.message || 'Error'))
        } else {
            return res
        }
    },
    error => {
        // console.log('error',error)
        Loading.service().close();
        if (error.response && (error.response.data.HttpStatusCode === 401 || error.response.data.status === 401)) {
            // store.dispatch('user/resetToken').then(() => {
            //     router.push("/login")
            // })
        }else{
            // error.response &&  Message({
            //     message: error.response.data.Message ? error.response.data.Message : error.message,
            //     type: 'error',
            //     duration: 5 * 1000
            // })
        }
        return Promise.reject(error)
    }
)

export default service
