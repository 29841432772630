export default {
  header: {
    The55thState: '第55洲',
    MyProfile: '身份信息',
    PointShop: '积分商城',
    InvitationCenter: '邀请中心',
    Announcement: '白皮书',
  },
  invite: {
    t1: '默认推荐',
    t2: '佣金比例 >',
    t3: '您获取',
    t4: '被邀请人获取',
    t5: '推荐码',
    t6: '推荐链接',
    t7: '邀请好友',
    t8: '我的邀请',
    t9: '积分商城',
    t10: '权益兑换',
    t11: '邀请规则',
    t12: '总邀请公民',
    t13: '总积分',
    t14: '推荐人UID',
    p1: '市场价',
    p2: '限时发售',
    p3: '邀请价',
    p4: '剩余',
    p5: '份',
    p6: '购买',
    rule1:
      '邀请规则<br>1. 邀请数量：<br>• 单个账户邀请好友的数量没有限制。<br>2. 邀请流程：<br> 每个55州护照将有一个邀请码，单个账号持有多个55州护照将会拥有多个邀请码<br>• 被邀请的好友接受邀请后，通过实名认证即可成为第55州会员，并在激活55州护照后成为第55州公民。<br>3. 禁止自我邀请：<br>• 任何用户不得通过多个账号进行自我邀请。违反此规则者需承担相应的法律责任，包括但不限于盗用他人信息的责任。<br>4. 数据同步：<br>• 成功邀请好友，被邀请者购买成功后，将在24小时内同步数据<br> 5. 规则调整：<br>• 由于市场环境的变化和欺诈风险的存在，第55州保留随时对奖励规则进行调整的最终解释权。',
  },
  profile: {
    t1: 'Upload files, <br/> Join the 55th State.',
    t2: 'Upload your profile and join the 55th district metaverse virtua',
    t3: 'Step Tips',
    t4: 'Fill in your invitation code, which cannot be modified after adding',
    s1: 'Agreement',
    s2: 'Passport',
    s3: 'Hold your identity card/driver license ',
    s4: 'Pending review ',
    d1: 'Read and Agree<br/>to the terms',
    d2: 'Upload your<br/>passport photos',
    d3: 'Upload a photo of you holding<br/>your own driver license',
    d4: 'Review process usually<br/>finishes in 24 hours',
    upload1: 'Click to Upload Or your can drop your files here',
    upload2:
      'Photo requirements: Make sure the picture is clear, showing all four corners of your document orcredentials, and please avoid glare.',
    upload3:
      "Your information will be securely encrypted and reviewed by a designated specialist. It's safe to submit your information and ducuments.",
    upload4:
      'You have completed the information submission and the review will be completed within 24 hours!',
    submit: 'Submit',
  },
  common: {
    unLogin: '请先登陆钱包',
    send: '发送',
    submit: '提交',
    login: '登录',
    register: '注册',
    message: '资料已提交，请注意邮箱',
    no_more: '没有更多数据..',
    loading: '加载中...',
    search: '搜索',
    select1: '请选择国家',
    select2: '请选择类型',
  },
  //   --------------------------
}
